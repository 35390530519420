// PLEASE DON'T ADD TO THIS JAVASCRIPT.
// Instead, make a Vue component.

app = ( function() {
	// Activated page editors
	this.editors = {};

	this.isAdminSideBarExpanded = function(){
		return $( "#sidenav-main" ).css( "width" ) === "215px";
	};

	/**
	 * Toggle Admin Side Bar
	 */
	this.toggleAdminSideBar = function(){
		$( "#navbar_brand" ).toggle();
		if ( isAdminSideBarExpanded() ){
			$( "#toggleAdminSideBarButton" )
				.addClass( "fa-rotate-90" );
			$( "#sidenav-main" )
				.css( "max-width", "62px" )
				.animate( { width: "62px" }, "fast" );
			$( "#main-content" )
				.removeClass( "main-content" )
				.addClass( "main-content-mini" );
		} else {
			$( "#toggleAdminSideBarButton" )
				.removeClass( "fa-rotate-90" );
			$( "#sidenav-main" )
				.css( "max-width", "215px" )
				.animate( { width: "215px" }, "fast" );
			$( "#main-content" )
				.removeClass( "main-content-mini" )
				.addClass( "main-content" );
		}
	};

	/**
	 * Activate simple MDE editors
	 * @param  {string} element The element ID to activate
	 */
	this.activateMDE = function( element ) {
		// activate the editor
		editors[element] = new SimpleMDE( {
			forceSync : true,
			element   : document.getElementById( element )
		} );

		return editors[element];
	};

	/**
	 * Reset the version form dialog
	 */
	this.resetVersionForm = function() {
		var $versionForm = $( "#versionForm" );
		$versionForm.find( "#versionID" ).val( "" );
		$versionForm.find( "#version" ).val( "" );
		$versionForm.find( "#downloadURL" ).val( "" );
		$versionForm.find( "#boxjson" ).val( "" );
		$versionForm.find( "#isStable" ).prop( "checked", "false" );
		$versionForm.find( "#versionFormLoader" ).fadeIn();
		$versionForm.find( "#versionFormBody" ).hide();
	};

	/**
	 * Show new version form
	 */
	this.newVersion = function() {
		resetVersionForm();
		var $versionForm = $( "#versionForm" );
		$versionForm.find( "#versionFormLoader" ).fadeOut();
		$versionForm.find( "#versionFormBody" ).fadeIn();
	};

	/**
	 * Show modal to edit a version
	 * @param  {string} versionID The version to edit
	 */
	this.editVersion = function( versionID ) {
		var self = this;
		var $versionForm = $( "#versionForm" );
		$versionForm.find( "#versionFormBody" ).hide();
		$versionForm.find( "#versionFormLoader" ).fadeIn();
		$.getJSON(
			"/package/getVersion",
			{ versionID: versionID },
			function( data ) {
				$versionForm.find( "#versionID" ).val( data.entryVersionID );
				$versionForm.find( "#boxjson" ).val( data.boxJSON );
				if ( data.isStable ) {
					$versionForm.find( "#isStable" ).prop( "checked", "checked" );
				}
				$versionForm.find( "#versionFormLoader" ).hide();
				$versionForm.find( "#versionFormBody" ).fadeIn();
			}
		);
	};

	/**
	 * Remove a version
	 * @param  {string} versionID The version ID to remove
	 */
	this.removeVersion = function( versionID ) {
		if ( confirm( "Really remove version forever!" ) ) {
			$( "#removeVersionID" ).val( versionID );
			$( "#removeVersionButton" ).attr( "disabled", true );
			$( "#versionRemoveForm" ).submit();
		}
	};

	/**
	 * Validate slug uniqueness on entry forms
	 */
	this.validateUniqueSlug = function() {
		var $slug = $( "#slug" );
		var $slugValidation = $( "#slug_validation" );
		var $slugSpinner = $( "#slug_spinner" );

		// Toggle Spinner
		$slugSpinner.toggle();
		$slugValidation.text( "" ).hide();

		// Private slug composition for validation
		var slugValue = $slug.val();
		if ( $( "#isPrivate" ).prop( "checked" ) ){
			slugValue = slugValue + "@" + $( "#username" ).val();
		}

		// Is slug filled out
		if ( !slugValue.length ){
			$slugValidation
				.text( "Please enter a slug in order to validate it." )
				.removeClass( "alert-success" )
				.addClass( "alert-danger" )
				.addClass( "m-0" )
				.fadeIn();
			$slugSpinner.toggle();
			return;
		}

		// Validate the slug in server
		$.post(
			"/package/slugCheck",
			{
				slug    		: slugValue,
				entryID 		: $( "#entryID" ).val() || "",
				isPrivate	: $( "#isPrivate" ).prop( "checked" )
			},
			function( data ) {
				if ( data.valid == false ){
					$slugValidation
						.text( "Slug is invalid! " + data.messages )
						.removeClass( "alert-success" )
						.addClass( "alert-danger" )
						.fadeIn();
				} else {
					if ( data.unique == true ) {
						$slugValidation
							.text( "Slug is valid!" )
							.removeClass( "alert-danger" )
							.addClass( "alert-success" )
							.fadeIn();
					} else {
						$slugValidation
							.text( "Slug is already in use!" )
							.removeClass( "alert-success" )
							.addClass( "alert-danger" )
							.fadeIn();
					}
				}

				$slugSpinner.toggle();
			},
			"json"
		);
	};

	/**
	 * Validate username usually in registration forms
	 */
	this.validateUsername = function() {
		var username = $( "#username_input" ).val();
		var validation = $( "#username_validation" );
		var spinner = $( "#username_spinner" );

		// verify length
		if ( !username.length ) {
			$( "#username_validation" )
				.text( "Please enter a username first!" )
				.addClass( "alert-danger" )
				.fadeIn();
			return;
		}

		//Toggle Spinner
		validation.text( "" ).hide();
		spinner.toggle();
		//Get REquest
		$.get( "/security/usernameCheck", { username: username }, function(
			data
		) {
			data = $.trim( data );
			// Check username
			if ( data == "false" ) {
				validation
					.text( "Username is available!" )
					.removeClass( "alert-danger" )
					.addClass( "alert-success" )
					.fadeIn();
			} else {
				validation
					.text( "Username is already in use" )
					.removeClass( "alert-success" )
					.addClass( "alert-danger" )
					.fadeIn();
			}
			spinner.toggle();
		} );
	};

	/**
	 * Prepare form submits by showing a {formID}-loader and hiding the {formID}-buttonBar
	 * @param  {string} formID The form ID
	 * @return {boolean}
	 */
	this.prepareFormSubmit = function( formID ) {
		document.getElementById( formID ).submit();
		$( "#" + formID + "-buttonbar" ).slideUp( "fast" );
		$( "#" + formID + "-loader" ).fadeIn( "slow" );
		return true;
	};

	/**
	 * Submit the registerForm
	 * @return {boolean}
	 */
	this.onSubmitRegistration = function() {
		document.getElementById( "registerForm" ).submit();
		document.getElementById( "recaptchaBtn" ).disabled = true;
		document.getElementById( "recaptchaBtn" ).classList.remove( "hover:tw-bg-blue-600" );
		document.getElementById( "recaptchaBtn" ).classList.remove( "tw-bg-blue-700" );
		document.getElementById( "recaptchaBtn" ).classList.add( "tw-bg-gray-200" );
		document.getElementById( "recaptchaBtn" ).classList.add( "hover:tw-bg-gray-200" );
		return true;
	};

	/**
	 * Submit the registerForm
	 * @return {boolean}
	 */
	this.onSubmitConfirmation = function() {
		document.getElementById( "confirmForm" ).submit();
		document.getElementById( "recaptchaBtn" ).disabled = true;
		document.getElementById( "recaptchaBtn" ).classList.remove( "hover:tw-bg-blue-600" );
		document.getElementById( "recaptchaBtn" ).classList.remove( "tw-bg-blue-700" );
		document.getElementById( "recaptchaBtn" ).classList.add( "tw-bg-gray-200" );
		document.getElementById( "recaptchaBtn" ).classList.add( "hover:tw-bg-gray-200" );
		return true;
	};

	/**
	 * Activate Ratings
	 */
	this.activateRatings = function() {
		// Startup Ratings
		$( ".ratings" ).barrating( {
			theme              : "fontawesome-stars",
			showSelectedRating : true,
			deselectable       : false,
			readonly           : false,
			onSelect           : function( value, text, event ) {
				var entryID = value.split( "_" )[0];
				var rating = value.split( "_" )[1];
				$.post(
					"/package/saveRating/",
					{ entryID: entryID, rating: rating },
					function( data ) {
						$( "#rating_message_" + entryID ).text( data.message );
					},
					"json"
				);
			}
		} );

		// Read only ratings
		$( ".ratings-readonly" ).barrating( {
			theme              : "fontawesome-stars",
			showSelectedRating : true,
			readonly           : true
		} );
	};

	/**
	 * Delete a stripe subscription
	 */
	this.deleteSubscription = function( subscriptionId ) {
		var endpoint = "/api/v1/subscriptions/";
		var id = subscriptionId;
		endpoint = endpoint.concat( id );
		fetch( endpoint, {
			method  : "delete",
			headers : { "Content-Type": "application/json" },
		} ).then( function( result ) {
			return result.json();
		} )
			.then( function( response ) {
				if ( !response.error ) {
					window.location = "/profile";
				} else {
					alert( "An error has occurred in the downgrade process" );
				}
			} );
	};

	/**
	 * Activate Datatables
	 */
	this.initDatatables = function() {
		// Init Datatables

		$( "#usersTable" ).dataTable( {
			"order" : [
				[
					1,
					"asc"
				]
			],
			"columnDefs" : [
				{
					"orderable" : false,
					"targets"   : [
						0,
						6
					]
				}
			]
		} );

		$( "#rolesTable" ).dataTable( {
			"order" : [
				[
					0,
					"asc"
				]
			],
			"columnDefs" : [ { "orderable": false, "targets": [ 4 ] } ]
		} );

		$( "#permissionsTable" ).dataTable( {
			"order" : [
				[
					0,
					"asc"
				]
			],
			"columnDefs" : [ { "orderable": false, "targets": [ 3 ] } ]
		} );

		$( "#packagesTable" ).dataTable( {
			"order" : [
				[
					0,
					"asc"
				]
			],
			"columnDefs" : [
				{
					"orderable" : false,
					"targets"   : [
						3,
						4,
						5,
						6,
						7
					]
				}
			]
		} );
	};

	/**
	 * Notify author of a new entry comment
	 * @param  {string} comment The comment posted
	 * @param  {string} slug    The package slug
	 */
	this.newEntryComment = function( comment, slug ) {
		$.post(
			"/comments/notifyAuthor",
			{ comment: comment, slug: slug },
			function( data ) {
				console.log( data );
			},
			"json"
		);
	};

	/**
	 * Verify if a string is JSON
	 * @param {string} str
	 */
	this.isJsonString = function( str ) {
		try {
			JSON.parse( str );
		} catch ( e ) {
			return false;
		}
		return true;
	};

	/**
	 * Activate tooltips
	 */
	this.activateTooltips = function(){
		$( "[data-toggle=\"tooltip\"]" )
			.tooltip( { trigger: "hover" } )
			.on( "click", function(){
				$( this ).tooltip( "hide" );
			} );
	};

	/**
	 * Constructor
	 */
	this.init = function() {
		// Tab link detector for bootstrap
		$( function() {
			if ( location.hash.length ){
				var activeTab = $( "[href=\"" + location.hash + "\"]" );
				if ( activeTab ){
					activeTab.tab( "show" );
				}
			}
		} );

		// Global Search Focus if it exists
		if ( $( "#search" ).length ){
			setTimeout( function() {
				$( "#search" ).focus();
			}, 500 );
		}

		// Activate Ratings on Page
		activateRatings();

		// Activate tooltips
		activateTooltips();

	};

	return this;
} )();

$( document ).ready( function() {

	// Init app
	app.init();

	if ( $( "#packageForm" ).length ){
		if ( $( "#description" ).length ){
			app.activateMDE( "description" );
		}

		if ( $( "#changelog" ).length ){
			app.activateMDE( "changelog" );
		}

		if ( $( "#installInstructions" ).length ){
			app.activateMDE( "installInstructions" );
		}

		if ( $( "#isPrivate" ).length ){
			$( "#isPrivate" ).change( function(){
				if ( $( this ).prop( "checked" ) ){
					$( "#private-message" ).fadeIn();
				} else {
					$( "#private-message" ).fadeOut();
				}
			} );
		}
	}

	if ( $( "#versionForm" ).length ) {
		$( "#versionForm" ).submit( function( event ) {
			var target = $( "#boxjson" ).val();
			var zipFile = $( "#zip" )[0];
			if ( !app.isJsonString( target ) ) {
				alert( "Content is not valid JSON, please check your content." );
				event.preventDefault();
			} else {
				var data = JSON.parse( target );
				var errors = "";
				if ( !_.has( data, "name" ) || !data.name.length ) {
					errors += "Please enter a 'name' for the package \n";
				}
				if ( !_.has( data, "version" ) || !data.version.length ) {
					errors += "Please enter a 'version' for the package \n";
				}
				if ( !_.has( data, "slug" ) || !data.slug.length ) {
					errors += "Please enter a 'slug' for the package \n";
				}
				if (
					( !_.has( data, "location" ) || !data.location.length ) &&
					zipFile.files.length === 0
				) {
					errors += "Please enter a 'location' for the package \n";
				}
				if ( errors.length ) {
					event.preventDefault();
					alert( errors );
				}
			}
		} );
	}

	if ( $( "#resetForm" ).length ) {
		$( "#resetForm" ).submit( function( event ) {
			if ( $( "#password" ).val() !== $( "#passwordConfirm" ).val() ) {
				event.preventDefault();
				alert( "Your passwords do not match, please try again!" );
				$( "#password" )
					.val( "" )
					.focus();
				$( "#passwordConfirm" ).val( "" );
				return;
			}
		} );
	}
} );
